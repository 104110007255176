import React from "react"

import Layoutcs from "../../../components/layoutcs/layoutcs"
import SEO from "../../../components/seo"

import Container from 'react-bootstrap/Container'

import Bloglist from "../../../components/bloglist/bloglist"

import "../../index.scss"
import "../../blog.scss"

import ImgBlogPost from "../../../images/blogpost/novy-plugin-neon-nette-support.jpg"

const Blog = () => (

	<Layoutcs>
		<SEO title="Nový plugin NEON Nette support" description="Kolega Pavel Keclík s přáteli organizuje charitativní Pivoňův běh, který letos proběhl vhorách přířodního parku jižních Čech poblíž Písku." />

		<Container className="ftrs-content pb-4 ftrs-blogpost">

			<h1 className="ftrs-h1">Nový plugin NEON Nette support</h1>
			<div className="ftrs-blogpost__date">09.02.2022</div>

			<img className="ftrs-blogpost__img" src={ImgBlogPost} alt="Nový plugin NEON Nette support" />

		</Container>

		<div className="ftrs-blogpost__text">
			<Container>

				<p>Pluginy pro&nbsp;IDE přinášejí pohodlí při&nbsp;vývoji a&nbsp;zjednodušují práci. Náš dlouholetý kolega Mesour vyvinul a&nbsp;Nette komunitě představil nový plugin NEON Nette Support pro&nbsp;PhpStorm, který je defacto bleskový i&nbsp;ve&nbsp;velkých projektech a&nbsp;ušetří developerům spoustu práce.</p>

				<p>Možná již znáte stávající NEON plugin, který obarvuje soubory a&nbsp;poskytuje základní našeptávání. To je dobré pro&nbsp;začátek nebo pro&nbsp;jiné využití, než jsou konfigurační soubory Nette. Ale co když chcete od&nbsp;NEON pluginu víc, minimálně to, že&nbsp;rozumí vaší Nette aplikaci, pomůže s&nbsp;refaktoringem, má pokročilé našeptávání apod? Aby rozeznal parametry %param% a&nbsp;služby @serviceName a&nbsp;znal jejich typ a&nbsp;mnoho dalšího? Podívejte se sami na&nbsp;přehled nejdůležitějších funkcí nového pluginu.</p>

				<h2 className="ftrs-h2 mt-4 mb-4">Funkce nového pluginu:</h2>

				<ul>
					<li>Obarvení syntaxe</li>
					<li>Našeptávání klíčů</li>
					<li>Našeptávání tříd a&nbsp;jmenných prostorů</li>
					<li>Našeptávání parametrů a&nbsp;odkazů na&nbsp;služby</li>
					<li>Refactoring</li>
					<li>Inspections</li>
					<li>Sekce includes</li>
					<li>a další novinky...</li>
				</ul>

				<p>Detailní funkce pluginu si můžete přečíst <a className="ftrs-blacklink" href="https://blog.nette.org/cs/novy-plugin-neon-nette-support" target="_blank" rel="noreferrer">v&nbsp;kompletním článku</a>.</p>


			</Container>
		</div>

		<Container className="pt-4 pb-4">
			<Bloglist></Bloglist>
		</Container>

	</Layoutcs>

)

export default Blog